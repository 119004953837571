// Messages
$success: #25bb48;
$error: #e4002b;

// App palette
$primary-hover: #4c5bdd;
$primary: #17238c;
$secondary: #fc5a60;
$secondary-hover: #d22128;
$white-hover: rgba(255, 255, 255, 0.3);

$blue-for-upload: #0044ff;

$accent: #f6b04e;

// Grayscale
$black: #333333;
$gray1: #727272;
$gray2: #ababab;
$gray3: #dbdbdb;
$gray4: #efefef;
$white: #ffffff;
$soft-gray: #f9f9f9;
$text-gray: #7e7e7e;


// Media Queries
$media-xxs: 480px;
$media-xs: 600px;
$media-sm: 960px;
$media-md: 1280px;
$media-lg: 1920px;
